import React, { lazy, Suspense, useState } from "react";
import axios from "axios";
import Input from "./components/Input";
import ErrorMessage from "./components/ErrorMessage";
import ImagineLiveSvg from "../src/assets/svgs/ImagineLiveSvg";
import ImagineLiveLoadingScreen from "./assets/images/ImagineLiveLoadingScreen.jpg";
import { INPUT_TYPES, MAIN_SERVER_URL } from "./constants";
import getDeviceOsAndBrowserInfo from "@livecasino/core/helpers/getDeviceOsAndBrowserInfo";

import styles from "./App.module.scss";

const { isMobile } = getDeviceOsAndBrowserInfo();

const extension = isMobile ? "mobile" : "desktop";
const LazyVideo = lazy(() => import(`components/VideoElement/index.${extension}.js`));

const App = () => {
    const [state, setState] = useState({
        username: "",
        password: "",
        error: null,
        isPasswordVisible: false
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setState(prevState => {
            return {
                ...prevState,
                [name]: value
            };
        });
    };

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            const { data } = await axios.post(MAIN_SERVER_URL, {
                username: state.username,
                password: state.password
            });
            if (data) {
                window.location.href = data;
            }
        } catch (error) {
            let errorMessage = "Something went wrong";
            if (error?.response?.data?.message) {
                errorMessage = error.response.data.message;
            }
            setState(prevState => {
                return {
                    ...prevState,
                    error: errorMessage
                };
            });
        }
    }

    const handleEyeIconClick = () => {
        setState(prevState => {
            return {
                ...prevState,
                isPasswordVisible: !prevState.isPasswordVisible
            };
        });
    };

    const passwordType = state.isPasswordVisible ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD;

    return (
        <div className={styles.App}>
            <div className={styles.VideoContainer}>
                <Suspense fallback={ImagineLiveLoadingScreen}>{<LazyVideo />}</Suspense>
            </div>
            <form className={styles.Form}>
                <div className={styles.FirstContent}>
                    <div className={styles.Logo}>
                        <ImagineLiveSvg />
                    </div>
                </div>
                <div className={styles.SecondContent}>
                    <div className={styles.TextContent}>
                        {state.error ? <ErrorMessage error={state.error} /> : "Log In"}
                    </div>
                    <div className={styles.InputContainer}>
                        <Input
                            label="Username"
                            onChange={handleChange}
                            value={state.username}
                            placeholder="Enter username"
                            name="username"
                            type="text"
                            error={state.error}
                            required
                        />
                    </div>
                    <div className={styles.InputContainer}>
                        <Input
                            label="Password"
                            onChange={handleChange}
                            value={state.password}
                            placeholder="Enter password"
                            name="password"
                            type={passwordType}
                            error={state.error}
                            handleEyeIconClick={handleEyeIconClick}
                            required
                            showEyeIcon
                        />
                    </div>
                    <div className={styles.ButtonContainer}>
                        <button type="submit" onClick={handleSubmit} className={styles.Button}>
                            LOGIN
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default App;
