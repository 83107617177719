import React, { memo } from "react";

const HideEyeSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16">
            <path
                d="M7.93591 9.83799C8.41286 9.83799 8.87028 9.65426 9.20754 9.32726C9.54473 9.00025 9.73422 8.5568 9.73422 8.09436C9.73422 8.04328 9.73182 7.99266 9.7273 7.94271L7.77951 9.83128C7.8311 9.83567 7.88309 9.83799 7.93591 9.83799ZM13.8883 2.7009L13.2024 2.03645C13.1783 2.01311 13.1457 2 13.1116 2C13.0776 2 13.0449 2.01311 13.0208 2.03645L11.2655 3.73883C10.2972 3.25902 9.20877 3.01911 8.00017 3.01911C4.91412 3.01911 2.61165 4.57749 1.09272 7.69422C1.03166 7.81956 1 7.95646 1 8.09509C1 8.23371 1.03166 8.37061 1.09272 8.49602C1.69965 9.73556 2.43047 10.7291 3.2852 11.4765L1.58661 13.1229C1.56254 13.1462 1.54901 13.1779 1.54901 13.2109C1.54901 13.2439 1.56254 13.2756 1.58661 13.299L2.27205 13.9635C2.29614 13.9868 2.3288 14 2.36285 14C2.3969 14 2.42956 13.9868 2.45365 13.9635L13.8883 2.87713C13.9003 2.86557 13.9097 2.85183 13.9162 2.83671C13.9227 2.82159 13.9259 2.80538 13.9259 2.78901C13.9259 2.77265 13.9227 2.75644 13.9162 2.74132C13.9097 2.7262 13.9003 2.71247 13.8883 2.7009ZM5.11 8.09436C5.10996 7.62108 5.23634 7.15585 5.47684 6.74402C5.71734 6.33221 6.06375 5.9878 6.48233 5.74437C6.90091 5.50093 7.37737 5.36676 7.86535 5.35495C8.35326 5.34312 8.83609 5.45405 9.26666 5.67691L8.48602 6.43384C8.17116 6.33609 7.83466 6.32432 7.51336 6.39981C7.19213 6.47531 6.89844 6.63514 6.66472 6.86179C6.43097 7.08843 6.26612 7.37312 6.18826 7.68465C6.1104 7.99618 6.12254 8.32246 6.22335 8.62768L5.44269 9.38465C5.22362 8.98776 5.10932 8.54445 5.11 8.09436Z"
                fill="#6C6C71"
            />
            <path
                d="M14.9072 7.69262C14.3421 6.53853 13.6694 5.59744 12.8892 4.86937L10.5748 7.11354C10.7698 7.608 10.8131 8.14663 10.6993 8.66441C10.5855 9.18218 10.3194 9.65699 9.93337 10.0313C9.54726 10.4057 9.05764 10.6636 8.52363 10.774C7.98955 10.8844 7.43403 10.8424 6.92406 10.6533L4.96101 12.5566C5.87173 12.9652 6.88467 13.1696 7.99983 13.1696C11.0859 13.1696 13.3883 11.6112 14.9072 8.49443C14.9684 8.36909 15 8.23219 15 8.09356C15 7.95493 14.9684 7.81803 14.9072 7.69262Z"
                fill="#6C6C71"
            />
        </svg>
    );
};

export default memo(HideEyeSvg);
