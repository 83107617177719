import React, { memo } from "react";

const ImagineLiveSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 187 36">
            <g id="Group_31527" data-name="Group 31527" transform="translate(2926 426)">
                <g id="Group_4" data-name="Group 4" transform="translate(-2926 -425.625)" fill="#FFFFFF">
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M399.768,316.247a10,10,0,1,0,5.952,18.042V336.1h4.088v-9.85a10.027,10.027,0,0,0-10.04-10Zm0,15.925a5.926,5.926,0,1,1,5.952-5.926,5.926,5.926,0,0,1-5.952,5.926Z"
                        transform="translate(-350.97 -309.909)"
                    />
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M804.333,316.247a10,10,0,1,0,8.12,15.875l-3.3-2.395a5.963,5.963,0,0,1-10.4-1.448h15.63v-2.033A10.027,10.027,0,0,0,804.333,316.247Zm-5.589,7.963a5.957,5.957,0,0,1,11.178,0Z"
                        transform="translate(-683.542 -309.909)"
                    />
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M689.993,316.247c-5.028,0-9.119,4.483-9.119,10V336.1h4.087v-9.85c0-3.268,2.257-5.927,5.031-5.927s5.031,2.658,5.031,5.927v9.85h4.088v-9.85C699.111,320.732,695.021,316.247,689.993,316.247Z"
                        transform="translate(-590.297 -309.907)"
                    />
                    <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M236.957,316.246a8.246,8.246,0,0,0-6.212,2.824,8.253,8.253,0,0,0-14.47,5.4V336.1h4.087V324.469a4.169,4.169,0,1,1,8.338,0V336.1h4.087V324.469a4.169,4.169,0,1,1,8.338,0V336.1h4.087V324.469A8.249,8.249,0,0,0,236.957,316.246Z"
                        transform="translate(-208.376 -309.906)"
                    />
                    <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M523.685,316.247a10,10,0,1,0,5.951,18.042v1.587a5.957,5.957,0,0,1-10.7,3.574l-3.259,2.457a10.047,10.047,0,0,0,18.045-6.031v-9.63A10.027,10.027,0,0,0,523.685,316.247Zm0,15.925a5.926,5.926,0,1,1,5.95-5.926,5.926,5.926,0,0,1-5.95,5.926Z"
                        transform="translate(-452.835 -310.248)"
                    />
                    <rect
                        id="Rectangle_6"
                        data-name="Rectangle 6"
                        width="4.088"
                        height="18.44"
                        transform="translate(83.588 7.725)"
                    />
                    <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M639.347,279.177a2.965,2.965,0,1,0,2.977,2.964A2.965,2.965,0,0,0,639.347,279.177Z"
                        transform="translate(-553.715 -279.177)"
                    />
                    <rect
                        id="Rectangle_7"
                        data-name="Rectangle 7"
                        width="4.088"
                        height="18.44"
                        transform="translate(0.91 7.725)"
                    />
                    <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M174.747,279.177a2.965,2.965,0,1,0,2.977,2.964A2.965,2.965,0,0,0,174.747,279.177Z"
                        transform="translate(-171.795 -279.177)"
                    />
                </g>
                <g id="Group_5" data-name="Group 5" transform="translate(-2791.88 -426)">
                    <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M1133.046,326.225a10.013,10.013,0,1,0-1.919,5.879l-3.3-2.4a5.963,5.963,0,0,1-10.4-1.447h15.63Zm-15.63-2.035a5.957,5.957,0,0,1,11.178,0Z"
                        transform="translate(-1080.169 -309.282)"
                        fill="#999"
                    />
                    <rect
                        id="Rectangle_8"
                        data-name="Rectangle 8"
                        width="4.088"
                        height="18.44"
                        transform="translate(7.099 8.356)"
                        fill="#999"
                    />
                    <rect id="Rectangle_9" data-name="Rectangle 9" width="4.088" height="26.796" fill="#999" />
                    <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M966.263,279.177a2.965,2.965,0,1,0,2.977,2.964A2.964,2.964,0,0,0,966.263,279.177Z"
                        transform="translate(-957.121 -278.802)"
                        fill="#999"
                    />
                    <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M1017.775,324.211l-5.4,13.038-5.4-13.038H1002.5l7.639,18.44h4.476l7.64-18.44Z"
                        transform="translate(-989.348 -315.855)"
                        fill="#999"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(ImagineLiveSvg);
