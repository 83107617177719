import React, { memo } from "react";

const ShowEyeSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16">
            <path
                d="M6.13751 8C6.13751 8.45559 6.32696 8.89253 6.66421 9.2147C7.00143 9.53686 7.45884 9.7178 7.93578 9.7178C8.41271 9.7178 8.87012 9.53686 9.20738 9.2147C9.54456 8.89253 9.73405 8.45559 9.73405 8C9.73405 7.54441 9.54456 7.10753 9.20738 6.78537C8.87012 6.4632 8.41271 6.28222 7.93578 6.28222C7.45884 6.28222 7.00143 6.4632 6.66421 6.78537C6.32696 7.10753 6.13751 7.54441 6.13751 8ZM14.9073 7.60429C13.3852 4.54142 11.0844 3 8.00003 3C4.91404 3 2.61483 4.54142 1.09272 7.60586C1.03166 7.72934 1 7.86421 1 8.00079C1 8.13736 1.03166 8.27223 1.09272 8.39572C2.61483 11.4586 4.91565 13 8.00003 13C11.0859 13 13.3852 11.4586 14.9073 8.39421C15.0309 8.14573 15.0309 7.85741 14.9073 7.60429ZM7.93578 10.6994C6.37514 10.6994 5.10992 9.49079 5.10992 8C5.10992 6.50921 6.37514 5.30062 7.93578 5.30062C9.4964 5.30062 10.7616 6.50921 10.7616 8C10.7616 9.49079 9.4964 10.6994 7.93578 10.6994Z"
                fill="#6C6C71"
            />
        </svg>
    );
};

export default memo(ShowEyeSvg);
