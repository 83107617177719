import React, { memo } from "react";

const ErrorSvg = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16">
            <path
                d="M8 11C8.16998 11 8.3126 10.9424 8.4278 10.8272C8.543 10.712 8.60042 10.5696 8.6 10.4C8.6 10.23 8.5424 10.0874 8.4272 9.9722C8.312 9.857 8.16962 9.79958 8 9.8C7.83002 9.8 7.6874 9.8576 7.5722 9.9728C7.457 10.088 7.39958 10.2304 7.4 10.4C7.4 10.57 7.4576 10.7126 7.5728 10.8278C7.688 10.943 7.83038 11.0004 8 11ZM7.4 8.6H8.6V5H7.4V8.6ZM8 14C7.17002 14 6.39 13.8424 5.66 13.5272C4.93 13.212 4.295 12.7846 3.755 12.245C3.215 11.705 2.7876 11.07 2.4728 10.34C2.158 9.60998 2.0004 8.82998 2 8C2 7.17002 2.1576 6.39 2.4728 5.66C2.788 4.93 3.2154 4.295 3.755 3.755C4.295 3.215 4.93 2.7876 5.66 2.4728C6.39 2.158 7.17002 2.0004 8 2C8.82998 2 9.60998 2.1576 10.34 2.4728C11.07 2.788 11.705 3.2154 12.245 3.755C12.785 4.295 13.2126 4.93 13.5278 5.66C13.843 6.39 14.0004 7.17002 14 8C14 8.82998 13.8424 9.60998 13.5272 10.34C13.212 11.07 12.7846 11.705 12.245 12.245C11.705 12.785 11.07 13.2126 10.34 13.5278C9.60998 13.843 8.82998 14.0004 8 14Z"
                fill="#D23A3A"
            />
        </svg>
    );
};

export default memo(ErrorSvg);
