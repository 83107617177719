import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GlobalStyle from "@livecasino/core/GlobalStyle";
import ErrorBoundary from "@livecasino/core/v2/components/ErrorBoundary";
import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <ErrorBoundary>
        <GlobalStyle />
        <App />
    </ErrorBoundary>
);
