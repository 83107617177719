import React from "react";
import PropTypes from "prop-types";
import ErrorSvg from "../../assets/svgs/ErrorSvg";

import styles from "./index.module.scss";

const ErrorMessage = ({ error = "" }) => {
    return (
        <div className={styles.ErrorMessage}>
            <div className={styles.Icon}>
                <ErrorSvg />
            </div>
            <span className={styles.Text}>{error}</span>
        </div>
    );
};

ErrorMessage.propTypes = {
    error: PropTypes.string
};

ErrorMessage.defaultProps = {
    error: ""
};

export default ErrorMessage;
